import { number, shape, string } from "prop-types";
import React from "react";
import { Image as DatoImage } from "react-datocms";

const ImageComponent = ({ image, ...props }) =>
  props.format === "svg" ? (
    <img src={props.imageUrl} alt={props.alt} />
  ) : (
    <DatoImage {...image} style={{ width: "100%" }} {...props} />
  );

export const ImageDataType = shape({
  srcSet: string.isRequired,
  webpSrcSet: string.isRequired,
  sizes: string.isRequired,
  src: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  aspectRatio: number.isRequired,
  bgColor: string.isRequired,
  base64: string.isRequired,
  title: string,
  alt: string,
}).isRequired;

export const ImageType = {
  data: ImageDataType,
};

ImageComponent.propTypes = {
  format: string,
  imageUrl: string,
  alt: string,
  image: shape(ImageType),
};

export default ImageComponent;
